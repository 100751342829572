import { TarificationType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/tarification.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageTarificationsUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/params/tarifications/`}
        model={
          new GenericData<TarificationType>({
            espaceId,
            params,
            collectionName: 'tarifications',
          })
        }
        name="tarification"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageTarificationsUpdate);
